#bytewise-oc-client {
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    user-select: text;
}

#bytewise-oc-client div {
    box-sizing: border-box;
}

.pulse {
    fill: white;
    fill-opacity: 0;
    transform-origin: 50% 50%;
    animation-duration: 2s;
    animation-name: pulse;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    from {
        stroke-width: 3px;
        stroke-opacity: 1;
        transform: scale(0.3);
    }
    to {
        stroke-width: 0;
        stroke-opacity: 0;
        transform: scale(2);
    }
}

.call-tooltip-dark {
    background: #3B3B3B !important;
    color: #D8D8D8 !important;
    opacity: 1 !important;
    box-shadow: 1px 1px #D8D8D8 !important;
}

.call-tooltip-light {
    background: #FFFFFF !important;
    color: #3B3B3B !important;
    opacity: 1 !important;
    box-shadow: 1px 1px #3B3B3B !important;
}


.emoji-picker-react > * {
    background: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
}

.emoji-picker-react .content-wrapper::before {
    content: unset !important;
}

.EmojiPickerReact {
    --epr-emoji-size: 1rem!important;
    --epr-category-navigation-button-size: 1rem!important;
    --epr-search-input-height: 2rem!important;
}

.emoji-group:before {
    background: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    position: unset !important;
}

.emoji {
    font-size: inherit !important;
}

.emoji-search {
    border-radius: 20px !important;
}
